import { PUBLIC_NODE_ENV } from '$env/static/public';

export function inDevelopment() {
	return PUBLIC_NODE_ENV === 'development';
}

export function inStaging() {
	return PUBLIC_NODE_ENV === 'staging';
}

export function inProduction() {
	return PUBLIC_NODE_ENV === 'production';
}

export function inTest() {
	return PUBLIC_NODE_ENV === 'test';
}
